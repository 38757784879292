var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-navigation"},[_c('nav',[_c('ul',_vm._l((_vm.tabs),function(ref){
var component = ref.component;
var icon = ref.icon;
var title = ref.title;
var notification = ref.notification;
return _c('li',{key:component.name},[_c('icon-button',{staticClass:"tab-button",class:{
                        'tab-button-active': _vm.selectedComponent === component,
                    },attrs:{"icon":icon,"title":title,"notification":notification},on:{"click":function($event){return _vm.select(component)}}})],1)}),0)]),_vm._v(" "),_c('div',{staticClass:"detail-wrapper"},[(_vm.selectedComponent)?_c(_vm.selectedComponent,_vm._b({tag:"component",staticClass:"detail-content"},'component',_vm.componentProps,false)):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }