






























import {Vue, Prop, Component} from 'vue-property-decorator'
import type {VueConstructor} from 'vue'

import {debounce} from 'throttle-debounce'

import type {IconButtonOptions} from '../baseComponents/IconButton.vue'

export type TabNavigationLink = {
    component: VueConstructor
} & Omit<IconButtonOptions, 'type'>

@Component({
    name: 'TabNavigation',
})
export default class TabNavigation extends Vue {
    @Prop({type: Object, required: true})
    private readonly options!: {
        tabs: TabNavigationLink[]
        scrollElement?: HTMLElement
    }

    @Prop()
    private readonly componentProps!: unknown

    selectedComponent: TabNavigationLink['component'] | null = null
    scrollPositions: Record<string, {top: number; left: number}> = {}
    debouncedScrollSaving = debounce(66, this.saveScrollPosition.bind(this))

    get tabs(): TabNavigationLink[] {
        return this.options.tabs
    }

    created(): void {
        if (this.tabs?.length) {
            this.selectedComponent = this.tabs[0].component
        }
    }

    mounted(): void {
        this.setScrollListener()
    }

    activated(): void {
        this.setScrollListener()
        this.scrollToSavedPosition(this.selectedComponent!)
    }

    destroyed(): void {
        this.removeScrollListener()
    }

    deactivated(): void {
        this.removeScrollListener()
    }

    setScrollListener(): void {
        this.$nextTick(() => {
            const {scrollElement} = this.options
            if (scrollElement) {
                scrollElement.addEventListener('scroll', this.debouncedScrollSaving, false)
            }
        })
    }

    removeScrollListener(): void {
        const {scrollElement} = this.options
        if (scrollElement) {
            scrollElement.removeEventListener('scroll', this.debouncedScrollSaving, false)
        }
    }

    saveScrollPosition(): void {
        const {scrollElement} = this.options
        if (scrollElement) {
            this.scrollPositions[this.selectedComponent!.name] = {
                top: scrollElement.scrollTop,
                left: scrollElement.scrollLeft,
            }
        }
    }

    scrollToSavedPosition(component: VueConstructor): void {
        const {scrollElement} = this.options
        if (scrollElement) {
            this.$nextTick(() => {
                const newScrollPos = this.scrollPositions[component.name] || {top: 0, left: 0}
                scrollElement.scroll(newScrollPos)
            })
        }
    }

    select(component: VueConstructor): void {
        this.selectedComponent = component
        this.scrollToSavedPosition(component)
    }
}
