import { render, staticRenderFns } from "./DbiCheckbox.vue?vue&type=template&id=6f591f48&scoped=true&"
import script from "./DbiCheckbox.vue?vue&type=script&lang=ts&"
export * from "./DbiCheckbox.vue?vue&type=script&lang=ts&"
import style0 from "./DbiCheckbox.vue?vue&type=style&index=0&id=6f591f48&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f591f48",
  null
  
)

export default component.exports