
import {Vue, Prop, Component} from 'vue-property-decorator'
import type {CreateElement, VNode} from 'vue'

import {debounce} from 'throttle-debounce'

@Component({
    name: 'AutoHideText',
})
export default class AutoHideText extends Vue {
    @Prop({type: String, required: true})
    private readonly content!: string

    childElements: VNode[] = []
    debouncedHideContext = debounce(66, this.hideContext.bind(this))

    mounted(): void {
        this.addResizeListener()
    }

    activated(): void {
        this.addResizeListener()
    }

    destroyed(): void {
        this.removeResizeListener()
    }

    deactivated(): void {
        this.removeResizeListener()
    }

    addResizeListener(): void {
        window.addEventListener('resize', this.debouncedHideContext, false)
        this.$nextTick(() => {
            this.debouncedHideContext()
        })
    }

    removeResizeListener(): void {
        window.removeEventListener('resize', this.debouncedHideContext, false)
    }

    render(createElement: CreateElement): VNode {
        const childElements = this.content.split(' ').map((text, index) => {
            if (index) {
                text = ' ' + text
            }
            return createElement('span', text)
        })

        this.childElements = childElements

        return createElement('div', {staticClass: 'auto-hide'}, childElements)
    }

    hideContext(): void {
        const el = this.$el as HTMLSpanElement
        const availWidth = el.offsetWidth
        let sum = 0
        this.childElements.forEach(({elm}) => {
            ;(elm as HTMLSpanElement).classList.remove('hidden')
            sum += (elm as HTMLSpanElement).offsetWidth
            ;(elm as HTMLSpanElement).classList.toggle('hidden', sum > availWidth)
        })
    }
}
