
import {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import {displayTime} from '@common/filters'

const component: FunctionalComponentOptions<{
    time?: Dbi.TimeEntry
    showCancelText: boolean
    showDiffString: boolean
    tag: string
    twoRows: boolean
}> = {
    name: 'TrainTime',
    functional: true,
    props: {
        time: {
            type: Object,
        },
        showDiffString: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'td',
        },
        showCancelText: {
            type: Boolean,
            default: true,
        },
        twoRows: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, {props, data}): VNode[] | VNode {
        const {time, showCancelText, tag, showDiffString, twoRows} = props
        const children = []

        if (time) {
            const {soll, prog, diffStr, canceled} = time

            children.push(
                <span class="soll">
                    {displayTime(soll)}
                    {diffStr && showDiffString && <span class="diff"> {diffStr}</span>}
                </span>,
            )
            if (canceled) {
                children.push(showCancelText ? <span class="canceled">Fällt aus</span> : <span>&mdash;</span>)
            }
            else if (prog) {
                children.push(<span class="prog">{displayTime(prog)}</span>)
            }
        }

        return createElement(
            tag,
            mergeData(data, {
                staticClass: 'time',
                class: {delayed: time?.delayed, 'two-rows': twoRows},
            }),
            children,
        )
    },
}
export default component
