
import {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import SvgSprite from '@common/components/baseComponents/SvgSprite.vue'
import {getTransportTypeIcon} from '@common/helper'

const component: FunctionalComponentOptions<{
    catCode: ValuesOf<Dbi.HafasTrafficType>
    showIcon: boolean
}> = {
    name: 'TransportSticker',
    functional: true,
    props: {
        catCode: {
            validator(value: unknown): boolean {
                return typeof value === 'number' && value >= 0 && value <= 9
            },
            required: true,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
    },

    render(createElement, {data, props, children}) {
        const {catCode, showIcon} = props

        const componentData = {
            staticClass: `transport-type transport-type-${catCode}`,
            class: {
                'with-icon': showIcon,
            },
        }

        const childElements = [createElement('span', {class: 'text-wrapper'}, children)]

        if (showIcon) {
            childElements.unshift(
                createElement(SvgSprite, {
                    class: 'travel-icon',
                    props: {icon: getTransportTypeIcon(catCode)},
                }),
            )
        }

        return createElement('div', mergeData(data, componentData), childElements)
    },
}
export default component
