















import {Vue, Component, Prop} from 'vue-property-decorator'

import TransportType from '@common/components/misc/TransportType.vue'

// TODO: clean-up / combine together with DbiTrainInformation

@Component({
    name: 'DbiMessages',
    components: {
        TransportType,
    },
})
export default class DbiMessages extends Vue {
    @Prop({type: Array, required: true})
    private readonly messages!: Dbi.HimMessage[]

    @Prop({type: Object, required: false})
    private readonly product!: Dbi.TripSectionTransport['product']
}
