
import type {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

import TransportSticker from '@common/components/misc/TransportSticker.vue'
import AutoHideText from '@common/components/misc/AutoHideText.vue'

const component: FunctionalComponentOptions<{
    product: Dbi.TripSectionTransport['product']
    showIcon: boolean
    hideNumbers: boolean
}> = {
    name: 'TransportType',
    functional: true,
    props: {
        product: {
            type: Object,
            required: true,
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        hideNumbers: {
            type: Boolean,
            default: false,
        },
    },

    render(createElement, {data, props}) {
        const {
            product: {type, number, line, catCode},
            showIcon,
            hideNumbers,
        } = props

        const content = `${type} ${line || number}`

        const trainNumber = hideNumbers
            ? createElement(AutoHideText, {props: {content}})
            : createElement('div', content)

        return createElement(TransportSticker, mergeData(data, {props: {catCode, showIcon}}), [trainNumber])
    },
}
export default component
