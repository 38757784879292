























import {Component, Prop, Mixins} from 'vue-property-decorator'

import {InputMixin, InputMixinOptions} from './inputMixin'

@Component({
    name: 'DbiCheckbox',
})
export default class DbiCheckbox<ValueType> extends Mixins<InputMixin<InputMixinOptions, unknown>>(InputMixin) {
    @Prop({default: true})
    private readonly checkboxValue!: ValueType

    get checked(): boolean {
        return Array.isArray(this.value) ? this.value.includes(this.checkboxValue) : this.value === this.checkboxValue
    }

    sendInput(event: InputEvent): void {
        const value = this.getUpdatedValue((event.target as HTMLInputElement).checked)

        this.$emit('change', value)
        this.$emit('input', value)
    }

    getUpdatedValue(isChecked: boolean): ValueType | ValueType[] | null {
        const checkboxValue = this.checkboxValue
        if (Array.isArray(this.value)) {
            const newValue = [...this.value]
            if (isChecked) {
                if (!newValue.includes(checkboxValue)) {
                    newValue.push(checkboxValue)
                }
            }
            else {
                const index = newValue.indexOf(checkboxValue)
                if (index >= 0) {
                    newValue.splice(index, 1)
                }
            }
            return newValue
        }

        return isChecked ? checkboxValue : null
    }
}
