
import {FunctionalComponentOptions, VNode} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

const component: FunctionalComponentOptions<{
    track: Dbi.Train['track']
    tag: string
    onlyChanges: boolean
    showText: boolean
}> = {
    name: 'TrainTrack',
    functional: true,
    props: {
        track: {
            type: Object,
            required: true,
        },
        tag: {
            type: String,
            default: 'td',
        },
        onlyChanges: {
            type: Boolean,
            default: false,
        },
        showText: {
            type: Boolean,
            default: false,
        },
    },
    render(createElement, {data, props, children}) {
        const {
            track: {soll, prog},
            tag,
            onlyChanges,
            showText,
        } = props

        if (!(soll || prog)) {
            return tag === 'td' ? <td></td> : []
        }

        const changed = !!prog && prog !== soll

        const childElements: Array<VNode | VNode[]> = [children]
        showText && childElements.push(createElement('span', {staticClass: 'text'}, 'Gl. '))
        !(onlyChanges && changed) && childElements.push(createElement('span', {staticClass: 'soll'}, soll))
        changed && childElements.push(createElement('span', {staticClass: 'prog'}, prog))

        const componentData = {
            staticClass: 'track',
            class: {
                changed,
                'change-only': onlyChanges,
            },
        }

        return createElement(tag, mergeData(data, componentData), childElements)
    },
}
export default component
